.todo {
  background-image: url("./images/fondo-dos.jpg");
  background-position: center;
  background-size: cover;
}
.btn {
  position: relative;
  font-size: 17px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.8em 2.2em;
  display: inline-block;
  border-radius: 1em;
  transition: all 0.2s;
  border: none;
  font-family: inherit;
  font-weight: 500;
  color: black;
  background-color: rgb(220 252 231);
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn::after {
  background-color: rgb(220 252 231);
}

.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.sombra {
  text-shadow: 3px 5px 30px black;
}
.sombraFuerte {
  text-shadow: 3px 5px 10px black;
}
.sombraSuave {
  text-shadow: 3px 10px 30px rgba(22, 22, 22, 0.271);
}
